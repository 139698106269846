import { graphql } from "gatsby"
import { getSrc, GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StyledLink } from "../../components/utils"
import { getProjectLink, urls } from "../../urls"
import S from "./styles"

const makeProjectDescription = project =>
  `${project.kind}, ${project.area}m2, ${project.location}`

/**
 * Build the image grid.
 *
 * Image grid is represented as list of items:
 * - `H` item - grid row with a single horizontal image
 * - list of `V` items - grid row with a list of vertical items
 *
 * @param {*} project - GraphQL project data
 * @param {*} images - GraphQL list of images data
 * @returns Image grid component
 */
const buildImageGrid = (project, images) => {
  let imageGrid = project.grid || []

  if (!imageGrid.length) {
    // If no grid is defined, map the images into one-column grid.
    return (
      <S.ImageGridContainer>
        {images.map(({ gatsbyImage, id, description }) => (
          <GatsbyImage
            image={gatsbyImage}
            key={id}
            alt={description || project.name}
          />
        ))}
      </S.ImageGridContainer>
    )
  } else {
    // Build grid according to given template.
    const rows = []
    let index = 0
    for (const rowItem of imageGrid) {
      if (rowItem === "H") {
        let imageData = images[index]
        const alt = imageData.description || project.name
        rows.push(
          <GatsbyImage
            image={imageData.gatsbyImage}
            key={imageData.id}
            alt={alt}
          />
        )
        index++
      } else if (rowItem.includes(",")) {
        const rowItems = rowItem.split(",").map(item => item.trim())
        const columns = []
        for (const columnItem of rowItems) {
          if (columnItem !== "") {
            let imageData = images[index]
            const alt = imageData.description || project.name
            columns.push(
              <div key={imageData.id}>
                <GatsbyImage image={imageData.gatsbyImage} alt={alt} />
              </div>
            )
            index++
          }
        }
        rows.push(<S.ImageColumns>{columns}</S.ImageColumns>)
      }
    }
    return <S.ImageGridContainer>{rows}</S.ImageGridContainer>
  }
}

const projectTemplate = ({ data, pageContext }) => {
  const project = data.contentfulProjekt
  const images = project.images
  const { next, previous } = pageContext

  const baseUrl = process.env.SITE_URL

  const jsonLdWebsite = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: new URL(urls.PROJECTS, baseUrl).href,
    name: "Projekty",
  }

  const jsonLdBreadcrumbs = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: process.env.SITE_URL,
        name: "Kamiko",
      },
      {
        "@type": "ListItem",
        position: 2,
        item: new URL(urls.PROJECTS, baseUrl).href,
        name: "Projekty",
      },
      {
        "@type": "ListItem",
        position: 3,
        item: new URL(getProjectLink(project.slug), baseUrl).href,
        name: project.name,
      },
    ],
  }

  const imageGrid = buildImageGrid(project, images)

  const projectDate = project.date && new Date(project.date)
  const projectDateRender =
    projectDate !== null ? (
      <>
        {projectDate.toLocaleString("pl", { month: "long" })}{" "}
        {projectDate.getFullYear()}
      </>
    ) : (
      <>-</>
    )

  const seoThumbnailSrc = getSrc(project.thumbnail)
  const seoDescription =
    project.metaDescription || makeProjectDescription(project)

  let renderedDocument = null
  try {
    renderedDocument = documentToReactComponents(
      JSON.parse(project.description.raw)
    )
  } catch (e) {}

  return (
    <Layout>
      <Seo
        title={project.name}
        description={seoDescription}
        url={getProjectLink(project.slug)}
        imageSrc={seoThumbnailSrc}
        jsonLdData={[jsonLdWebsite, jsonLdBreadcrumbs]}
      />
      <h1>{project.name}</h1>
      <S.FeaturesContainer>
        <S.Table>
          <tbody>
            <tr>
              <S.TdName>Zakres</S.TdName>
              <S.TdValue>{project.kind}</S.TdValue>
            </tr>
            <tr>
              <S.TdName>Powierzchnia</S.TdName>
              <S.TdValue>
                {project.area ? (
                  <>
                    {project.area} m<sup>2</sup>
                  </>
                ) : (
                  <>-</>
                )}
              </S.TdValue>
            </tr>
            <tr>
              <S.TdName>Miejsce</S.TdName>
              <S.TdValue>{project.location}</S.TdValue>
            </tr>
            <tr>
              <S.TdName>Data</S.TdName>
              <S.TdValue>
                <span style={{ textTransform: "capitalize" }}>
                  {projectDateRender}
                </span>
              </S.TdValue>
            </tr>
            {project.author && (
              <tr>
                <S.TdName>Autor</S.TdName>
                <S.TdValue>
                  <span style={{ textTransform: "capitalize" }}>
                    {project.author}
                  </span>
                </S.TdValue>
              </tr>
            )}
            <tr>
              <S.TdName>Zdjęcia</S.TdName>
              <S.TdValue>{project.photographer}</S.TdValue>
            </tr>
          </tbody>
        </S.Table>
      </S.FeaturesContainer>
      {renderedDocument && (
        <div style={{ paddingBottom: "2rem", textAlign: "justify" }}>
          {renderedDocument}
        </div>
      )}
      {imageGrid}
      {(next || previous) && (
        <S.Pagination>
          <S.PaginationItem style={{ textAlign: "left" }}>
            {previous &&
              (previous.comingSoon === null ||
                previous.comingSoon === false) && (
                <StyledLink to={getProjectLink(previous.slug)}>
                  &larr; {previous.name}
                </StyledLink>
              )}
          </S.PaginationItem>
          <S.PaginationItem style={{ textAlign: "right" }}>
            {next &&
              (next.comingSoon === null || next.comingSoon === false) && (
                <StyledLink to={getProjectLink(next.slug)}>
                  {next.name} &rarr;
                </StyledLink>
              )}
          </S.PaginationItem>
        </S.Pagination>
      )}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulProjekt(slug: { eq: $slug }) {
      name
      author
      metaDescription
      description {
        raw
      }
      slug
      kind
      area
      location
      date
      photographer
      grid
      thumbnail {
        gatsbyImage(
          formats: [PNG]
          placeholder: BLURRED
          width: 1200
          height: 630
          layout: FIXED
        )
      }
      images {
        id
        description
        gatsbyImage(placeholder: BLURRED, width: 1200, layout: CONSTRAINED)
      }
    }
  }
`

export default projectTemplate
